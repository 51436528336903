import React from "react"

import { withReturnsCard } from "./withReturnsCard"
import { Wrapper } from "./ReturnsCardStyles"
import { RichText } from "../../../RichText/RichText"

export const ReturnsCard = withReturnsCard(({ content }) => (
  <Wrapper>
    <RichText>{content}</RichText>
  </Wrapper>
))
