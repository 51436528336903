import React from "react"

import { withReturns } from "./withReturns"
import { Layout } from "../Layout/Layout"
import { ReturnsCard } from "./Card/ReturnsCard"

export const Returns = withReturns(
  ({ uri, paths }): JSX.Element => (
    <Layout uri={uri} paths={paths}>
      <ReturnsCard />
    </Layout>
  )
)
