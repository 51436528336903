import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { Returns as Page } from "../../components/Customer/Returns/Returns"

import { useHotjar } from '../../hooks/useHotjar'

export const query = graphql`
  query {
    page: sanityPageAccountReturns {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = withAuthentication(({ data, ...props }): JSX.Element => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("returns_page")

  return <Page {...props} {...data} />
})
export default Component
